.footer {
    background-color: #282c34;
    color: white;
    text-align: center;
    padding: 0px 0px 20px 0px;
    position: relative;
    bottom: 0;
  }

.footer p {
  font-size: 0.9rem;
  letter-spacing: .1rem;
}
  
.footer-links {
    margin-top: 8px;
}
.made-by{
  font-size: 0.8rem !important;
}
  
.footer-links a {
    font-size: 0.8rem;
    color: #61dafb;
    text-decoration: none;
    margin: 0 10px;
}
  
.footer-links a:hover {
    text-decoration: underline;
}
  