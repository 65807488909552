
.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: rgba(51,51,51,0.8);
    color: white;
    padding: 10px 0;
    transition: top 0.3s;
    z-index: 1001;
  }
  
  .navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  .navbar li {
    margin: 0 15px;
  }
  
  .navbar a {
    color: white;
    text-decoration: none;
    font-size: 16px;
  }
  
  .navbar.hide {
    top: -60px; /* Adjust based on the height of your navbar */
  }
  
  .navbar.show {
    top: 0;
  }
  